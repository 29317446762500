* {
  box-sizing: border-box;
}

body {
   font-family: Arial, sans-serif;
}

.nav {
  background-color: #333;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 0 1rem;
}

.site-title {
  font-size: 2rem;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

.nav li.active {
  background-color: #555;
}

.nav li:hover {
  background-color: #777;
}

.padding {
        padding: 1in;
}

/* Image and text content container */
.content-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
}

/* Left half of the screen */
.left-half {
        width: 50%;
}

/* Right half of the screen */
.right-half {
        width: 50%;
}

/* Image placeholder */
.image-placeholder {
        width: 100%;
        height: auto;
        padding-top: 15px;
}

/* Text content */
.text-content {
        padding: 2rem;
}

      


